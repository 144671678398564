import loadable from "@loadable/component";
import classnames from "classnames";
import { inject } from "mobx-react";
import * as React from "react";
import type { Store } from "@inferno/renderer-shared-core";
import { Remote } from "../../components/remote/Remote.component";
import { getChartV2, LATEST_LIST_SLUG } from "../../services/Charts";
import { Container } from "../../ui";
import type { PageBlockInterface } from "../Block.component";
import "./ChartListPageLoader.style.scss";

const ChartListLoader = loadable(() => import("./ChartListLoader.component"));

interface ChartListPageLoaderProps extends PageBlockInterface {
  store?: Store;
}

export const ChartListPageLoader: React.FunctionComponent<ChartListPageLoaderProps> = inject("store")(
  ({ store, block, params, isPrimary }: ChartListPageLoaderProps) => {
    const chartSlug: string | undefined = block?.value?.chart_slug || params?.chart_slug;
    const listSlug: string = block?.value?.list_slug || params?.list_slug || LATEST_LIST_SLUG;

    if (!store || !chartSlug) {
      // Note that list_slug is allowed to be empty
      return null;
    }

    const { tags } = block;
    const showAsWidget = !!block.tags?.includes("display-hints/small");
    const css = classnames("component-chartlist-page", {
      [``]: tags?.includes("display-hints/themed-block"),
    });

    return (
      <Container className={css}>
        <Remote
          // lists are always needed for metadata
          loader={() => getChartV2({ slug: chartSlug, includeLists: true })}
          cacheKey={`chart-list-page-${chartSlug}`}
        >
          {({ data: chart }) =>
            chart ? (
              <ChartListLoader
                showAsWidget={showAsWidget}
                listSlugOnLoad={listSlug}
                isPrimary={isPrimary}
                chartSlug={chartSlug}
                block={block}
                chart={chart}
              />
            ) : null
          }
        </Remote>
      </Container>
    );
  },
);

export default ChartListPageLoader;
