import stringify from "fast-json-stable-stringify";
import type { ICache } from "@ihr-radioedit/inferno-core";
import type {
  ChartDatesListFragment,
  ChartListPartialFragment,
  ChartListPartialResultsFragment,
  GetChartListQueryVariables,
  GetChartListsPartialsQueryVariables,
  GetChartV2QueryVariables,
} from "@ihr-radioedit/inferno-webapi";
import { Cursor } from "@ihr-radioedit/inferno-core";
import { ILog } from "@ihr-radioedit/inferno-core";
import { getSdk, sdkOpts } from "@ihr-radioedit/inferno-core";
// import { ObservableMap } from "mobx";

const sdk = getSdk(sdkOpts());

export const LATEST_LIST_SLUG = "latest";

const log = ILog.logger("Charts");

export interface MonthsByYear {
  [year: number]: number[];
}

export const parseChartDates = (dates?: ChartDatesListFragment | null) => {
  if (!dates) {
    return null;
  }

  const years: number[] = [];
  const monthsByYear: MonthsByYear = {};

  dates.months.forEach(({ year, months }) => {
    years.push(year);
    monthsByYear[year] = months;
  });

  return { years, monthsByYear };
};

export const getChartV2 = (query: GetChartV2QueryVariables) =>
  sdk!.GetChartV2.queryAsPromise(query).then(r => r.data?.charts?.charts.bySlug);

export const getChartList = (query: GetChartListQueryVariables) =>
  sdk!.GetChartList.queryAsPromise(query).then(r => r.data?.charts?.lists.bySlug);

export const chartListsCursor =
  (cache: Map<string, ICache>) => (initialData: ChartListPartialResultsFragment, chartSlug: string) =>
    new Cursor<ChartListPartialResultsFragment, GetChartListsPartialsQueryVariables, ChartListPartialFragment>(
      { chartSlug, cursor: initialData.pageInfo.nextCursor },
      async opts => {
        try {
          const response = await sdk!.GetChartListsPartials.queryAsPromise(opts);
          return response.data?.charts?.lists.filter || { items: [], pageInfo: { nextCursor: null } };
        } catch (e) {
          log.debug("Error in loader", e && e.response ? e.response.data.errors : e);
          throw e;
        }
      },
      data =>
        data.items.length
          ? {
              hasMore: !!data.pageInfo.nextCursor,
              newData: data.items,
              opts: { chartSlug, cursor: data.pageInfo.nextCursor },
            }
          : { opts: { chartSlug, cursor: null }, hasMore: false, newData: [] },
      opts => `getEvents-${stringify(opts)}`,
      cache,
      { initialData },
    );
